import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams} from "react-router-dom";
import logo from '../assets/images/logo3.png'
import logo3 from '../assets/images/logo3.png'
import axios from "axios";
import bg from "../assets/images/Canvas.png";
import badger from "../assets/images/badger.png";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function Verify(){
    const [searchParams, setSearchParams] = useSearchParams()
    const [certId,setCertId] = useState()
    const [certData, setCertData] = useState()
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState("")
    useEffect(()=>{
        if(searchParams){
            setError("")
            let id = searchParams.get('id')
            console.log(id)
            setCertId(id)
            setLoading(true)
            axios.get(`/checkCert?certId=${id}`).then(data=>{
                setError("")
                console.log(data)
                setCertData(data.data)
                setLoading(false)
            }).catch(err=>{
                setError(err.message)
                setLoading(false)
                console.log(err)
            })
        }
    },[])

    const handleSearch =()=>{
        if(certId==""){
            return toast.error('certificate id invalid')
        }
        setLoading(true)
        axios.get(`/checkCert?certId=${certId}`).then(data=>{
            setError("")
            console.log(data)
            setCertData(data.data)
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
            toast.error(err.message)
            setError(err.message)
            console.log(err)
        })
    }

    return <div>
        <div className={'flex justify-between items-center px-5 py-3 border-b shadow'}>

            <img src={logo}  className={'w-[150px]'}/>
            <div className={'flex items-center space-x-3'}>
                <span className={'font-semibold '}>Certificate Id: </span>
                <input className={'bg-gray-300 rounded border focus:outline-none text-[12px] px-2 py-1 ' } value={certId}  onChange={(e=>{setCertId(e.target.value)})}  />
                <button
                    onClick={handleSearch}
                    className={'bg-indigo-500  text-white text-[12px] px-2 py-1 rounded '}>Search</button>
            </div>


        </div>
        {!loading && error.length==0 ?<div className={'mt-5 grid grid-cols-4 gap-3'}>
            <div className={'col-span-3'}>
                {certData && <div className={'w-full relative '} style={{backgroundImage:`url(${bg})`, backgroundRepeat:"no-repeat", backgroundSize: "100% 100%" }}>
                    <div className={'py-36 flex-col items-center justify-center flex'}>
                        <div><img  src={logo3}/></div>
                        <h1 className={'uppercase text-[2.875rem] mt-20 text-title'}>Equest Education Group</h1>
                        <h3 className={'text-title uppercase text-xl mt-10'}>Hereby certifies that </h3>
                        {certData.user &&<h3 className={' py-8 font-cardo uppercase text-name text-[3.875rem]'}>{certData.user.name}</h3>}
                        <p className={'uppercase text-xl text-title font-bold italic'}>Has successfully completed the course</p>
                        {certData.course_detail && <p className={'uppercase text-xl text-title font-bold'}>{certData.course_detail.name}</p>}
                        {certData.cert && <div className={'mt-20'}>
                            <p className={'text-title  text-sm mb-4 '}>Time Iusse: {new Date(certData.cert.update).toLocaleString()}</p>
                            <p  className={'text-title  text-sm mb-4'}>Certificate Id: {certData.cert._id}</p>
                            <p  className={'text-title  text-sm mb-4'}>txId: {certData.cert.txId}</p>

                        </div>}
                    </div>
                    <img className={'w-1/4  absolute right-5 bottom-5'} src={badger} />


                </div>}

            </div>
            <div className={'col-span-1'}>
                {certData && certData.certFabric &&<div className={'font-cardo'}>
                    <h1 className={'text-title font-semibold text-[18px] '}>Data Verify on Blockchain:</h1>
                    <hr/>
                    <p className={'text-[14px] text-title mb-2'}><span className={'font-semibold'}>Course Id:</span> {certData.certFabric.course}</p>
                    <p  className={'text-[14px] text-title mb-2'}><span className={'font-semibold'}>name:</span> {certData.certFabric.name}</p>
                    <p  className={'text-[14px] text-title mb-2'}><span className={'font-semibold'}>studentId:</span> {certData.certFabric.studentId}</p>
                    <p  className={'text-[14px] text-title mb-2'}><span className={'font-semibold'}>Grade:</span> {certData.certFabric.Grade}</p>
                    <hr/>
                    {certData.user && certData.course_detail &&<div>
                        <span className={'text-[18px] font-semibold text-title'}>Status:</span>
                        <span className={'text-[18px] font-semibold text-name'}>{certData.course_detail.id+""==certData.certFabric.course+"" && certData.user.id+"" == certData.certFabric.studentId+"" ?"Verified":"Unverified" }</span>
                    </div>}
                </div>}

            </div>
        </div>:<div className={'w-full h-screen flex justify-center items-center'}>
            {loading && <h1 className={'text-title font-semibold '}>
                Loading...
            </h1>}
            {
                error.length >0 && <h1 className={'text-title font-semibold '}>
                    Can't verify your certificate by Id: {certId}
                </h1>
            }

        </div>}
        <ToastContainer/>
    </div>
}
