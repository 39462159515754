import React, {useEffect, useState} from 'react'
import bg from '../assets/images/Canvas.png'
import logo from '../assets/images/logo3.png'
import badger from '../assets/images/badger.png'
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Home(){

    const [info, setInfo] = useState(null)
    const [certHash ,setCertHash] = useState(null)
    const [err, setErr]= useState(false)
    const [loading, setLoading] = useState(false)
    const [getCertLoad,setGetCertLoad] = useState(false)

    const getLtik = () => {
        const searchParams = new URLSearchParams(window.location.search)
        const ltik = searchParams.get('ltik')
        if (!ltik) throw new Error('Missing lti key.')
        return ltik
    }

    const copy = async () => {
        await navigator.clipboard.writeText(`https://megacert.megaschool.edu.vn/?id=${certHash._id}`);
        toast("Link copied!");
    }

    const verifyCert = (data)=>{
        axios.post('/lti/checkverify', data, {
            headers: {
                Authorization: 'Bearer ' + getLtik()
            }
        })
    }

    useEffect(()=>{
        const get_info = async()=>{
            try {
                setLoading(true)
                const launchInfo = await axios.get('/lti/info',
                    {
                        headers: {
                            Authorization: 'Bearer ' + getLtik()
                        }
                    })
                setInfo(launchInfo.data)
                if(launchInfo.data.cert){
                    setCertHash(launchInfo.data.cert)
                }
                setLoading(false)
            } catch (err) {
                setLoading(false)
                console.log(err)
            }
        }
        get_info()
    },[])





    const mintCert = async()=>{
        try{
            setGetCertLoad(true)
            let certInfo = await axios.post('/lti/createCert', {}, {
                headers: {
                    Authorization: 'Bearer ' + getLtik()
                }
            })
            console.log(certInfo.data)
            toast.success('Your certificate have been issued!')
            setCertHash(certInfo.data)
            setGetCertLoad(false)
        }catch (err){
            console.log(err.message)
            toast.error(err.message)
            setGetCertLoad(false)
        }


    }

    return <div className={'w-full flex flex-col justify-between   '} style={{backgroundImage:`url(${bg})`, backgroundRepeat:"no-repeat", backgroundSize: "100% 100%" }}>
        <div className={'py-36 flex-col w-full items-center justify-center flex '}>
            <div>
                <img  src={logo}/>
            </div>

            <h1 className={'uppercase text-[2.875rem] mt-20 text-title'}>Equest Education Group</h1>
            <h3 className={'text-title uppercase text-xl mt-10'}>Hereby certifies that </h3>
            {info && info.name && <h3 className={' py-8 font-cardo uppercase text-name text-[3.875rem]'}>{info.name}</h3>}
            <p className={'uppercase text-xl text-title font-bold italic'}>Has successfully completed the course</p>
            {info && info.course && <p className={'uppercase text-xl text-title font-bold'}>{info.course.name}</p>}
            { certHash== null && <button  disabled={getCertLoad} onClick={()=>{mintCert()}} className={'text-white mt-3 text-[12px] bg-indigo-600 px-2 py-1 rounded font-semibold'}>{getCertLoad?"Processing...":"Issue Certificate"}</button>}
            {certHash && <div className={'mt-20'}>
                <p className={'text-title  text-sm mb-4 '}>Time Iusse: {new Date(certHash.update).toLocaleString()}</p>
                <p  className={'text-title  text-sm mb-4 '}>Certificate Id: {certHash._id}</p>
                <p  className={'text-title  text-sm  mb-4'}>txId: {certHash.txId}</p>
                <button onClick={copy}
                    className={'text-white mt-3 bg-indigo-500 text-[12px] font-semibold px-2 py-1 rounded '}>Share</button>
            </div>}
        </div>

        {certHash &&
            <div className={'flex justify-end'}>
                <img className={'w-1/4 mb-10 mr-5 '} src={badger} />
            </div>}
        <ToastContainer />
    </div>
}
