import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Verify from './pages/Verify'

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Verify/>}/>
          <Route  path={'/lti'} element={<Home/>}/>
          {/*<Route path={'/lti/listCert'} element={<ListCert/>}/>*/}
        </Routes>
      </BrowserRouter>
  )
}

export default App;
